import { tranEv } from './common';

export class Cookie {
  constructor(selector: string) {
    const cookiePop: HTMLElement = document.querySelector(selector);

    if (cookiePop) {
      const btn: HTMLElement = cookiePop.querySelector('.btn');

      btn.addEventListener('click', () => {
        this.setCookie('acceptCookie', 'true', 365);
        cookiePop.classList.add(`${selector.substring(1)}--hide`);
        cookiePop.addEventListener(
          tranEv,
          () => {
            cookiePop.parentElement.removeChild(cookiePop);
          },
          {
            once: true,
          },
        );
      });
    }
  }

  private setCookie(cname: string, cvalue: string, exdays: number): void {
    let expires: string = '';

    if (exdays > 0) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      expires = `expires=${d.toUTCString()}`;
    } else {
      expires = '01 Jan 1970 00:00:01 GMT';
    }

    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  }

  private getCookie(cname: string): string {
    const name: string = `${cname}=`;
    const ca: string[] = document.cookie.split(';');

    ca.forEach((cai: string) => {
      let c: string = cai;
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }

      return '';
    });

    return '';
  }

  private checkCookie(cname: string): boolean {
    return this.getCookie(cname) !== '';
  }

  private deleteCookie(cname: string): void {
    this.setCookie(cname, '', 0);
  }
}

import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

declare global {
  var google: any;
}

export class Map {
  mapDiv: HTMLElement;
  markersData: any[] = [];
  markers: any[] = [];
  mainMarkerData: any;
  mainMarker: any;
  infoWindows: any[] = [];
  styles: any[] = [];
  center: any = {
    lat: 54.7000902,
    lng: 25.1128515,
  };
  map: any;

  constructor(selector: string) {
    const mapDiv: HTMLElement = document.querySelector(selector);

    if (mapDiv) {
      this.mapDiv = mapDiv;
      google.maps.event.addDomListener(window, 'load', this.getMapInfo());
    }
  }

  private setupMap(): void {
    const mapOptions: any = {
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      scrollwheel: false,
      fullscreenControl: false,
      zoomControl: false,
      zoom: 15,
      styles: this.styles,
    };

    this.map = new google.maps.Map(this.mapDiv, mapOptions);
    this.map.setCenter(new google.maps.LatLng(this.center.lat, this.center.lng));

    const zoomIn: HTMLElement = this.mapDiv.parentElement.querySelector('.map__control--zoomin');
    const zoomOut: HTMLElement = this.mapDiv.parentElement.querySelector('.map__control--zoomout');
    const filters: HTMLFormElement = this.mapDiv.parentElement.querySelector('.map__filtersform');

    if (zoomIn) {
      zoomIn.addEventListener('click', () => {
        this.map.setZoom(this.map.getZoom() + 1);
      });
    }

    if (zoomOut) {
      zoomOut.addEventListener('click', () => {
        this.map.setZoom(this.map.getZoom() - 1);
      });
    }

    if (filters) {
      filters.onchange = (e: Event) => {
        const checked = filters.querySelectorAll('input[type="checkbox"]:checked');
        const values = Array.prototype.map.call(checked, (cbox: HTMLInputElement) => {
          return cbox.value;
        });

        this.filterMarkers(values);
      };

      filters.onsubmit = (e: Event) => {
        e.preventDefault();
      };
    }

    google.maps.event.addListener(this.map, 'click', (e: Event) => {
      this.infoWindows.forEach((infoWindow) => { infoWindow.close(); });
    });

    this.setupMarkers();
  }

  private filterMarkers(types: string[]): void {
    if (types.length > 0) {
      this.markers.forEach((marker: any) => {
        if (types.indexOf(marker.type) > -1) {
          marker.setVisible(true);
        } else {
          marker.setVisible(false);
        }
      });
    } else {
      this.markers.forEach((marker: any) => {
        marker.setVisible(true);
      });
    }
  }

  private setupMarkers(): void {
    if (this.mainMarkerData) {
      const mData: any = this.mainMarkerData;
      this.mainMarker = new google.maps.Marker({
        position: new google.maps.LatLng(mData.location.lat, mData.location.lng),
        map: this.map,
        animation: google.maps.Animation.DROP,
        icon: {
          url: mData.pin.url,
          scaledSize: new google.maps.Size(mData.pin.size.width, mData.pin.size.height),
          origin: new google.maps.Point(mData.pin.origin.x, mData.pin.origin.y),
        },
        type: mData.type,
      });
    }

    if (this.markersData.length > 0) {
      this.markers = this.markersData.map((data: any) => {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(data.location.lat, data.location.lng),
          map: this.map,
          animation: google.maps.Animation.DROP,
          icon: {
            url: data.pin.url,
            scaledSize: new google.maps.Size(data.pin.size.width, data.pin.size.height),
            origin: new google.maps.Point(data.pin.origin.x, data.pin.origin.y),
          },
          type: data.type,
        });

        const infoWindow = this.makeInfowindow(data.info);

        google.maps.event.addListener(marker, 'click', () => {
          this.infoWindows.forEach((iW) => { iW.close(); });
          infoWindow.open(this.map, marker);
        });

        return marker;
      });
    }
  }

  private makeInfowindow(info: any): any {
    const template:string = `
      <div class="map__tooltip">
        <p>${info.name}</p>
        <div class="map__infos">
          <div class="map__info map__info--drive">
            <p>${info.drive}</p>
          </div>
          <div class="map__info map__info--distance">
            <p>${info.distance}</p>
          </div>
        </div>
      </div>
    `;

    const infoWindow: any = new google.maps.InfoWindow({
      content: template,
    });

    this.infoWindows.push(infoWindow);

    return infoWindow;
  }

  private getMapInfo(): void {
    this.pinsRequest().then(() => {
      this.setupMap();
    });
  }

  private async pinsRequest(): Promise<any> {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: forJs.requestUrl,
      params: {
        action: 'getMapData',
      },
    };

    return await axios(config).then((resp: AxiosResponse) => {
      if (resp.data.markers) {
        this.markersData = this.markersData.concat(resp.data.markers);
      }

      if (resp.data.mainMarker) {
        this.mainMarkerData = resp.data.mainMarker;
      }

      if (resp.data.map) {
        if (resp.data.map.center) {
          this.center = resp.data.map.center;
        }

        if (resp.data.map.styles) {
          this.styles = JSON.parse(resp.data.map.styles);
        }
      }

    }).catch((error: AxiosError) => {
      console.log(error.response);
    });
  }
}

import { tranEv } from './common';

export class ShowBlock {
  block: HTMLElement;
  openBtn: HTMLElement;
  closeBtn: HTMLElement;
  blockSelect: string;

  constructor(blockSelect: string, openBtnSelect: string, closeBtnSelect: string) {
    this.blockSelect = blockSelect;
    this.block = document.querySelector(blockSelect);
    this.openBtn = document.querySelector(openBtnSelect);
    this.closeBtn = document.querySelector(closeBtnSelect);

    if (this.block && this.openBtn) {
      this.setupListeners();
    }
  }

  private setupListeners(): void {
    this.openBtn.addEventListener('click', () => this.openBlock());

    if (this.closeBtn) {
      this.closeBtn.addEventListener('click', () => this.closeBlock());
    }
  }

  private openBlock(): void {
    this.block.classList.add(`${this.blockSelect.substring(1)}--exist`);

    setTimeout(
      () => {
        this.block.classList.add(`${this.blockSelect.substring(1)}--open`);
      },
      0,
    );
  }

  private closeBlock(): void {
    this.block.classList.remove(`${this.blockSelect.substring(1)}--open`);
    this.block.addEventListener(
      tranEv,
      () => {
        this.block.classList.remove(`${this.blockSelect.substring(1)}--exist`);
      },
      {
        once: true,
      },
    );
  }
}

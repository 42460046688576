import {
  IListData,
  IFilter,
  ITableCol,
  IItem,
} from '../interfaces/interfaces';
import * as noUiSlider from 'nouislider';
import perfectScrollbar from 'perfect-scrollbar';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { isMS } from './common';
import { Floors } from './floors';

interface IFilterValues {
  slug: string;
  values: number[];
}

export class FilteredList {
  data: IListData;
  list: HTMLElement;
  filters: HTMLElement;
  tables: HTMLElement;
  tablesScroll: perfectScrollbar;

  constructor(selector: string) {
    const list: HTMLElement = document.querySelector(selector);

    if (list) {
      this.list = list;
      this.filters = this.list.querySelector('.filters__wrap');
      this.tables = this.list.querySelector('.list__tables');

      this.getListData();
    } else {
      this.addFloors();
    }
  }

  private getListData(): void {
    this.listDataRequest().then(() => {
      this.list.classList.add('list--loaded');
      this.setupFilters();
    });
  }

  private setFreeSwitch(): void {
    const checkbox: HTMLInputElement = this.list.querySelector('#filters__freeonly');

    checkbox.onchange = () => {
      if (checkbox.checked) {
        this.list.classList.add('list--freeonly');
      } else {
        this.list.classList.remove('list--freeonly');
      }
    };
  }

  private async listDataRequest(): Promise<any> {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: forJs.requestUrl,
      params: {
        action: 'getListData',
      },
    };

    return await axios(config).then((resp: AxiosResponse) => {
      if (resp.data) {
        this.data = resp.data;
      }

    }).catch((error: AxiosError) => {
      console.log(error.response);
    });
  }

  private setupFilters(): void {
    this.setCloseSwitch();
    this.setFreeSwitch();
    this.addForm();
    this.addList();
    this.addFloors();

    this.tablesScroll = new perfectScrollbar(this.tables, {
      wheelPropagation: true
    });
    this.tables.scrollLeft = 0;
  }

  private setCloseSwitch(): void {
    const closeswitch: HTMLElement = this.filters.parentNode.querySelector('.filters__close');

    closeswitch.addEventListener('click', () => {
      closeswitch.classList.toggle('filters__close--closed');

      if (closeswitch.classList.contains('filters__close--closed')) {
        this.filters.classList.add('filters__wrap--closed');
      } else {
        this.filters.classList.remove('filters__wrap--closed');
      }
    });
  }

  private addFloors(): void {
    new Floors('.floors', this.data ? this.data : null);
  }

  private addForm(): void {
    const wrap: HTMLElement = this.list.querySelector('.filters__wrap');
    const { filters } = this.data.apartments;
    const form: string = `
      <form class="filters__form">
        ${ filters.map((filter: IFilter) => {
          const { slug, title, min, max, step, postfix } = filter;
          return `
            <div
              class="filters__rangewrap filters__col"
              data-min="${min}"
              data-max="${max}"
              data-postfix="${postfix}"
              data-step="${step}"
            >
              <h5>${title}</h5>
              <input class="value" type="hidden" name="${slug}"/>
              <div class="filters__range"></div>
            </div>
          `;
        }).reduce((total, cur) => {
          return `${total}${cur}`;
        }) }
      </form>
    `;

    wrap.insertAdjacentHTML('beforeend', form);

    const formEl: HTMLFormElement = wrap.querySelector('.filters__form');
    const rangewraps: NodeListOf<HTMLElement> = formEl.querySelectorAll('.filters__rangewrap');

    if (rangewraps) {
      this.setupRangers(rangewraps);
    }

    formEl.onchange = () => {
      const items: IItem[] = this.data.apartments.items;
      const fltrs: NodeListOf<HTMLInputElement> = formEl.querySelectorAll('input[type="hidden"]');
      const filters: IFilterValues[] = [];

      fltrs.forEach((input: HTMLInputElement) => {
        filters.push({
          slug: input.name,
          values: JSON.parse(input.value),
        });
      });

      const arrays: any[] = this.buildArrays(filters, items);
      const ids: string[] = (arrays.length > 1) ? this.returnMatching(arrays) : arrays[0];
      this.applyFilters(ids);
    };
  }

  private addList(): void {
    const { apartments } = this.data;
    const list: string = `
      <div class="list__table">
        ${ this.getListHead(apartments.tablecols) }
        ${ this.getListBody(apartments.tablecols, apartments.items) }
      </div>
    `;

    this.list.querySelector('.list__tables').insertAdjacentHTML('beforeend', list);
  }

  private getListHead(tablecols: ITableCol[]): string {
    return `
      <div class="list__head">
        <div class="container list__head">
          <div class="wrap list__row">
            ${ tablecols.map((tablecol: ITableCol) => {
              return `
                <div class="list__col">
                  <h5>${tablecol.title}</h5>
                </div>
              `;
            }).reduce((total, cur) => {
              return `${total}${cur}`;
            }) }
          </div>
        </div>
      </div>
    `;
  }

  private getListBody(tablecols: ITableCol[], items: IItem[]): string {
    return `
      ${ items.map((item: IItem) => {
        const { id, status, url, statusTitle } = item;
        return `
          ${ url !== '#' ? `<a
              href="${url}"
              id="list-${id}"
              data-id="${id}"
              class="list__item list__item--${ status ? 'free' : 'sold' } list__item--show"
            >` : `<div
              id="list-${id}"
              data-id="${id}"
              class="list__item list__item--${ status ? 'free' : 'sold' } list__item--show"
            >`
          }
            <div class="container">
              <div class="wrap list__row">
                ${ tablecols.map((tablecol: ITableCol) => {
                  let value: string = (item as any)[tablecol.slug];

                  if (tablecol.slug === 'status') {
                    value = statusTitle;
                  }

                  return `
                    <div class="list__col">
                      <p>${ value }</p>
                    </div>
                  `;
                }).reduce((total, cur) => {
                  return `${total}${cur}`;
                }) }
              </div>
            </div>
          ${ url !== '#' ? '</a>' : '</div>' }
        `;
      }).reduce((total, cur) => {        
        return `${total}${cur}`;
      }) }
    `;
  }

  private setupRangers(wraps: NodeListOf<HTMLElement>) {
    wraps.forEach((wrap: HTMLElement) => {
      const ranger: HTMLElement = wrap.querySelector('.filters__range');

      const options: any = {
        min: wrap.getAttribute('data-min') ? parseInt(wrap.getAttribute('data-min'), 10) : 1,
        max: wrap.getAttribute('data-max') ? parseInt(wrap.getAttribute('data-max'), 10) : 1,
        step: wrap.getAttribute('data-step') ? parseInt(wrap.getAttribute('data-step'), 10) : 1,
        postfix: wrap.getAttribute('data-postfix') ? ` ${wrap.getAttribute('data-postfix')}` : ''
      };

      const minp: HTMLElement = document.createElement('p');
      minp.classList.add('min');
      minp.innerHTML = options.min + options.postfix;

      const maxp: HTMLElement = document.createElement('p');
      maxp.classList.add('max');
      maxp.innerHTML = options.max + options.postfix;

      wrap.appendChild(minp);
      wrap.appendChild(maxp);

      noUiSlider.create(ranger, {
        start: [options.min, options.max],
        margin: options.step,
        step: options.step,
        connect: true,
        tooltips: true,
        range: {
          min: options.min,
          max: options.max,
        },
        format: {
          to(val: number) {
            return Math.floor(val) + options.postfix;
          },
          from(val: string) {
            return val.replace(options.postfix, '');
          },
        },
      });

      const tmp: any = (ranger as any).noUiSlider;
      const input: HTMLInputElement = wrap.querySelector('input.value');

      if (tmp && input) {
        tmp.on('update', (values: number[], handle: number, unencoded: number[]) => {
          input.value = JSON.stringify(unencoded);
          let evt: any;
          if (isMS) {
            evt = document.createEvent('CustomEvent');
            evt.initCustomEvent('change', true, true, window);
          } else {
            evt = new Event('change', { bubbles: true });
          }
          input.dispatchEvent(evt);
        });
      }
    });
  }

  private applyFilters(ids: string[]) {
    const items: NodeListOf<HTMLElement> = this.tables.querySelectorAll('.list__item');

    items.forEach((item: HTMLElement) => {
      const id: string = item.getAttribute('data-id');

      if (ids.indexOf(id) > -1) {
        item.classList.add('list__item--show');
      } else {
        item.classList.remove('list__item--show');
      }
    });
  }

  private returnMatching(arrays: any[]): string[] {

    return arrays.shift().reduce(
      (res: any, v: any) => {
        if (res.indexOf(v) === -1 && arrays.every((a: any) => {
          return a.indexOf(v) !== -1;
        })) res.push(v);
        return res;
      },
      [],
    );
  }

  private buildArrays(filters: IFilterValues[], items: IItem[]): any[] {

    return filters.map((filter: IFilterValues) => {
      const tmp: IItem[] = items.filter((item: IItem) => {
        const min: boolean = (item as any)[filter.slug] >= filter.values[0];
        const max: boolean = (item as any)[filter.slug] <= filter.values[1];
        return min && max;
      });

      return tmp.map((item: IItem) => item.id);
    });
  }
}

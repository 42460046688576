import { tranEv } from './common';

export class Modal {
  constructor(selector: string) {
    const modals: NodeListOf<HTMLElement> = document.querySelectorAll(selector);

    if (modals) {
      const togglers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-modal]');

      if (togglers) {
        togglers.forEach((toggler: HTMLElement) => {
          toggler.addEventListener('click', () => {
            this.openModal(document.querySelector(`#${toggler.dataset.modal}`));
          });
        });
      }

      modals.forEach((modal: HTMLElement) => {
        const inner: HTMLElement = modal.querySelector('.modal__inner');
        const closeBtn: HTMLElement = inner.querySelector('.modal__close');

        modal.addEventListener('click', (e: Event) => {
          const inInner = inner.contains(<HTMLElement>e.target);
          if (!inInner) {
            this.closeModal(modal);
          }
        });

        if (closeBtn) {
          closeBtn.addEventListener('click', (e: Event) => {
            this.closeModal(modal);
          });
        }

        const videoWraps: NodeListOf<HTMLElement> = modal.querySelectorAll('.modal__video');

        if (videoWraps) {
          videoWraps.forEach((videoWrap: HTMLElement) => {
            this.modalVideo(videoWrap.querySelector('video'), videoWrap);
          });
        }
      });
    }
  }

  private openModal(modal: HTMLElement): void {
    modal.classList.add('modal--open');
    document.body.classList.add('fixed');

    const video = modal.querySelector('video');

    if (video) {
      video.play();
    }
  }

  private closeModal(modal: HTMLElement): void {
    modal.classList.remove('modal--open');
    document.body.classList.remove('fixed');

    const video = modal.querySelector('video');

    if (video) {
      modal.addEventListener(
        tranEv,
        () => {
          video.pause();
          video.currentTime = 0;
        },
        {
          once: true,
        },
      );
    }
  }

  private modalVideo(video: HTMLVideoElement, wrap: HTMLElement): void {
    video.addEventListener('loadedmetadata', (e: Event) => {
      this.setVideoSize(video, wrap);
      window.addEventListener('resize', () => this.setVideoSize(video, wrap));
    });

    if (video.readyState >= 2) {
      this.setVideoSize(video, wrap);
      window.addEventListener('resize', () => this.setVideoSize(video, wrap));
    }
  }

  private setVideoSize(video: HTMLVideoElement, wrap: HTMLElement): void {
    const contWidth = wrap.offsetWidth;
    const contHeight = wrap.offsetHeight;
    const vidWidth = video.videoWidth;
    const vidHeight = video.videoHeight;

    const vidHeightToWidth = vidHeight / vidWidth;
    const vidWidthToHeight = vidWidth / vidHeight;

    if (contHeight * vidWidthToHeight > contWidth) {
      video.style.width = `${contWidth}px`;
      video.style.height = `${contWidth * vidHeightToWidth}px`;
    } else {
      video.style.width = `${contHeight * vidWidthToHeight}px`;
      video.style.height = `${contHeight}px`;
    }
  }
}

import { animEv, isMS } from './common';

export class Header {
  header: HTMLElement;
  toggler: HTMLElement;

  constructor(selector: string) {
    const header: HTMLElement = document.querySelector(selector);

    if (header) {
      this.header = header;
      this.toggler = this.header.querySelector('.header__toggler');
      this.setupHeader();
    }
  }

  private setupHeader() {
    if (!isMS) {
      this.header.querySelector('.header__logo').classList.add('go');
      

      this.toggler.addEventListener('click', (e: Event) => {
        this.toggler.classList.toggle('header__toggler--open');
      });
    } else {
      this.header.querySelector('.header__logo').classList.add('ie');
      this.header.classList.add('header--line');
    }

    window.addEventListener('scroll', () => this.headerScroll());
  }

  private headerScroll() {
    if (window.pageYOffset > 10) {
      this.header.classList.add('header--scrolled');
    } else {
      this.header.classList.remove('header--scrolled');
    }
  }
}

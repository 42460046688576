import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

export class Form {
  form: HTMLFormElement;
  inputs: NodeListOf<HTMLInputElement>;
  error: HTMLElement;
  submit: HTMLElement;

  constructor(selector: string) {
    const form: HTMLFormElement = document.querySelector(selector);

    if (form) {
      this.form = form;
      this.inputs = this.form.querySelectorAll('.form__input input');
      this.error = this.form.querySelector('.form__error');
      this.submit = this.form.querySelector('.form__submit .btn');

      this.setInputListeners();
      this.setSubmitListener();
    }
  }

  private setInputListeners(): void {
    this.inputs.forEach((input: HTMLInputElement) => {
      input.addEventListener('focusin', () => {
        input.parentElement.classList.add('form__input--focus');
      });

      input.addEventListener('focusout', () => {
        input.parentElement.classList.remove('form__input--focus');
      });

      input.addEventListener('keyup', () => this.inputChange(input));
      input.addEventListener('change', () => this.inputChange(input));
    });
  }

  private setSubmitListener(): void {
    this.form.onsubmit = (e: Event) => {
      e.preventDefault();

      if (this.validateInputs()) {
        this.hideError();
        this.sendForm();
      } else {
        this.showError();
      }
    };
  }

  private sendForm(): void {
    const formData: FormData = new FormData(this.form);

    const config: AxiosRequestConfig = {
      method: 'post',
      url: forJs.requestUrl,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const pageNameField: HTMLInputElement = this.form.querySelector('input[name="pagename"]');
    const pageName: string = pageNameField ? pageNameField.value : document.title;

    gtag('event', pageName, { event_label: 'Forma' });

    this.submit.classList.add('btn--loading');

    axios(config).then((resp: AxiosResponse) => {
      if (resp.data.status === 'success') {
        this.showSuccess(resp.data.message);
      } else if (resp.data.status === 'warning') {
        this.showError(resp.data.message, resp.data.fields);
      } else {
        this.showError();
      }
    }).catch((resp: AxiosError) => {
      console.log(resp);
    });
  }

  private clearForm(): void {
    this.form.reset();

    this.inputs.forEach((input: HTMLInputElement) => {
      const classes = ['form__input--error', 'form__input--full',  'form__input--focus'];
      input.parentElement.classList.remove(...classes);
    });
  }

  private hideError(): void {
    if (this.error.classList.contains('form__error--show')) {
      this.error.classList.remove('form__error--show');
    }
  }

  private showError(message: string = forJs.formErrorMessage, fields?: string[]): void {
    this.error.innerText = message;
    this.error.classList.add('form__error--show');

    this.submit.classList.remove('btn--loading');

    if (fields) {
      fields.forEach((field: string) => {
        const theField: HTMLElement = this.form.querySelector(`input[name="${field}"]`);
        theField.parentElement.classList.add('form__input--error');
      });
    }
  }

  private showSuccess(message: string): void {
    const success: HTMLElement = this.submit.querySelector('.from__success');
    this.submit.classList.remove('btn--loading');

    if (success) {
      success.parentNode.removeChild(success);
    }

    const newSuccess: HTMLElement = document.createElement('div');
    newSuccess.classList.add('form__success');
    newSuccess.innerText = message;
    this.submit.appendChild(newSuccess);
    this.clearForm();
  }

  private validateInputs(): boolean {
    let count: number = 0;

    this.inputs.forEach((input: HTMLInputElement) => {
      if (this.validateInput(input)) {
        input.parentElement.classList.remove('form__input--error');
      } else {
        input.parentElement.classList.add('form__input--error');
        count = count + 1;
      }
    });

    return count < 1;
  }

  private validateInput(input: HTMLInputElement): boolean {
    if (input.value.trim().length < 1) {
      return false;
    }

    if (input.type === 'email') {
      const regex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return regex.test(input.value);
    }

    // if (input.type === 'checkbox') {
    //   return input.checked;
    // }

    return true;
  }

  private inputChange(input: HTMLInputElement): void {
    const val: string = input.value.trim();

    if (val.length > 0) {
      input.parentElement.classList.add('form__input--full');
      input.parentElement.classList.remove('form__input--error');
    } else {
      input.parentElement.classList.remove('form__input--full');
    }
  }
}

import { Offers } from './components/offers';
import { Visuals } from './components/visuals';
import { Calc } from './components/calc';
import { Cookie } from './components/cookie';
import { Header } from './components/header';
import { Modal } from './components/modal';
import { Slider } from './components/slider';
import { Form } from './components/form';
import { Map } from './components/maps';
import { MouseMover } from './components/mousemover';
import { ShowBlock } from './components/showblock';
import { FilteredList } from './components/filteredlist';
import { ScrollTos } from './components/scrolltos';
import { Floors } from './components/floors';
import '../scss/style.scss';

/* tslint:disable */
(function () {
  // @ts-ignore
  const Promise: any = require('es6-promise-promise');

  if (typeof NodeList.prototype.forEach !== 'function') {
    // @ts-ignore
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  if (typeof SVGElement.prototype.contains !== 'function') {
    SVGElement.prototype.contains = function contains(node) {
      if (!(0 in arguments)) {
        throw new TypeError('1 argument is required');
      }

      do {
        if (this === node) {
          return true;
        }
      } while (node = node && node.parentNode);

      return false;
    };
  }

  return false;
})();
/* tslint:enable */

declare global {
  var forJs: any;
  var gtag: any;
}

const onLoad = (e: Event) => {
  onResize(e);
  inits();
};

const onResize = (e: Event) => {
  onScroll(e);
};

const onScroll = (e: Event) => {
};

const inits = () => {
  new Header('.header');
  new Modal('.modal');
  new Slider('.about__swiper');
  new Slider('.gallery__slides', '.gallery__thumbs');
  new Slider('.developer__textslides', '.developer__bgslides');
  new Slider('.developer__projectslides');
  new Form('.contact__form .form');
  new Map('#map');
  new Cookie('.cookie');
  new MouseMover('.developer__projectslist', '.developer');
  new ShowBlock('.developer__projects', '.btn--openprojects', '.developer__back');
  new FilteredList('.list--filtered');
  new ScrollTos('[data-scrollto]');
  new Visuals();
  new Calc('.calc');
  new Offers('.list--offers');
};

window.addEventListener('load', onLoad);
window.addEventListener('resize', onResize);
window.addEventListener('scroll', onScroll);

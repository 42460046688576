import swiper from 'swiper';
import { animEv } from './common';

interface IOptionsObject {
  [key: string]: any;
}

export class Slider {
  swiper: any;
  thumbnails: any = {};

  constructor(selector: string, thumbs: string | false = false) {
    const slider: HTMLElement = document.querySelector(selector);

    if (slider) {
      const resizeAboutHeading = this.resizeAboutHeading;

      this.swiper = new swiper(selector, {
        ...this.getOptions(selector, thumbs),
        on: {
          init() {
            if (document.querySelector('.header').classList.contains('header--line')) {
              resizeAboutHeading(selector);
            } else {
              document.querySelector('.header').addEventListener(
                animEv,
                () => {
                  resizeAboutHeading(selector);
                },
                {
                  once: true,
                },
              );
            }
          },
          resize() {
            resizeAboutHeading(selector);
          },
        },
      });
    }
  }

  private getOptions(selector: string, thumbs: string | false): IOptionsObject {
    let options: IOptionsObject = {};
    let loop: boolean = true;

    const slider: HTMLElement = document.querySelector(selector);

    if (slider.parentElement.classList.contains('gallery--noloop')) {
      loop = false;
    }

    if (thumbs) {
      if (thumbs === '.developer__bgslides') {
        this.thumbnails = new swiper(thumbs, {
          slidesPerView: 1,
          loop: true,
          spaceBetween: 0,
          effect: 'fade',
          simulateTouch: false,
          shortSwipes: false,
          longSwipes: false,
          followFinger: false,
          allowTouchMove: false,
          fadeEffect: {
            crossFade: true,
          },
        } as IOptionsObject);
      } else {
        this.thumbnails = new swiper(thumbs, {
          loop,
          spaceBetween: 15,
          slidesPerView: 6,
          freeMode: false,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          touchRatio: 0,
          centerInsufficientSlides: true,
        } as IOptionsObject);
      }
    }

    if (selector === '.about__swiper') {
      options = {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };
    }

    if (selector === '.developer__textslides') {
      options = {
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        loop: true,
      };
    }

    if (selector === '.developer__projectslides') {
      options = {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        loop: true,
      };
    }

    if (selector === '.gallery__slides') {
      options = {
        loop,
        autoHeight: true,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };
    }

    if (thumbs) {
      options = {
        ...options,
        thumbs: {
          swiper: this.thumbnails,
        },
      };
    }

    return options;
  }

  private resizeAboutHeading = (selector: string) => {
    let slides: NodeListOf<HTMLElement>;

    if (this.swiper) {
      slides = this.swiper.el.querySelectorAll('.about__slide');
    } else {
      slides = document.querySelector(selector).querySelectorAll('.about__slide');
    }

    slides.forEach((slide: HTMLElement) => {
      const headingHeight: number = slide.querySelector('h3').offsetHeight;
      const padding: number = 75;
      const textBlock: HTMLElement = slide.querySelector('.about__text');
      const offsetHeight: number = textBlock.offsetHeight - padding - headingHeight;

      textBlock.style.webkitTransform = `translateY(${offsetHeight}px)`;
      textBlock.style.transform = `translateY(${offsetHeight}px)`;
    });
  }
}

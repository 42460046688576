import * as noUiSlider from 'nouislider';
import { isMS, triggerChange } from './common';

export class Calc {
  elem: HTMLElement;
  form: HTMLFormElement;

  priceVal: string;
  startMoneyVal: string;
  startPercentVal: string;
  interestVal: string;
  yearsVal: string;

  price: HTMLInputElement;
  startMoney: HTMLInputElement;
  startPercent: HTMLInputElement;
  interest: HTMLInputElement;
  years: HTMLInputElement;

  constructor(selector: string) {
    const elem: HTMLElement = document.querySelector(selector);

    if (elem) {
      this.elem = elem;
      this.form = this.elem.querySelector('form');

      this.price = this.form.querySelector('#price');
      this.startMoney = this.form.querySelector('#startermoney');
      this.startPercent = this.form.querySelector('#starterpercent');
      this.interest = this.form.querySelector('#interest');
      this.years = this.form.querySelector('#years');

      this.priceVal = this.price.value;
      this.startMoneyVal = this.startMoney.value;
      this.startPercentVal = this.startPercent.value;
      this.interestVal = this.interest.value;
      this.yearsVal = this.years.value;

      const rangewraps: NodeListOf<HTMLElement> = this.form.querySelectorAll('.calc__input--range');

      if (rangewraps) {
        this.setupRangers(rangewraps);
      }

      const startMoney: HTMLInputElement = this.form.querySelector('#startermoney');
      if (startMoney) {
        startMoney.addEventListener('focusin', () => {
          startMoney.parentElement.classList.add('calc__input--focus');
        });

        startMoney.addEventListener('focusout', () => {
          startMoney.parentElement.classList.remove('calc__input--focus');
        });
      }

      this.formChange();
      this.form.onchange = () => this.formChange();
    }
  }

  private formChange() {
    this.setValues();
    this.resultCalc();
  }

  private setValues() {
    let priceCur: string = this.price.value ? this.price.value : '1';
    let startMoneyCur: string = this.startMoney.value ? this.startMoney.value : '1';
    let startPercentCur: string = this.startPercent.value ? this.startPercent.value : '1';
    let interestCur: string = this.interest.value ? this.interest.value : '1';
    let yearsCur: string = this.years.value ? this.years.value : '1';

    if (priceCur !== this.priceVal) {
      if (parseInt(this.price.min, 10) > parseInt(priceCur, 10)) {
        priceCur = this.price.min;
      } else if (parseInt(this.price.max, 10) < parseInt(priceCur, 10)) {
        priceCur = this.price.max;
      }
    }

    if (startMoneyCur !== this.startMoneyVal) {
      startPercentCur = String(parseFloat(startMoneyCur) / parseFloat(priceCur) * 100);

      if (parseInt(this.startPercent.min, 10) > parseFloat(startPercentCur)) {
        startPercentCur = this.startPercent.min;
      } else if (parseInt(this.startPercent.max, 10) < parseFloat(startPercentCur)) {
        startPercentCur = this.startPercent.max;
      }

      startPercentCur = String(Math.round(parseFloat(startPercentCur) * 100) / 100);
    }

    if (interestCur !== this.interestVal) {
      if (parseInt(this.interest.min, 10) > parseFloat(interestCur)) {
        interestCur = this.interest.min;
      } else if (parseInt(this.interest.max, 10) < parseFloat(interestCur)) {
        interestCur = this.interest.max;
      }
    }

    if (yearsCur !== this.yearsVal) {
      if (parseInt(this.years.min, 10) > parseInt(yearsCur, 10)) {
        yearsCur = this.years.min;
      } else if (parseInt(this.years.max, 10) < parseInt(yearsCur, 10)) {
        yearsCur = this.years.max;
      }
    }

    this.startMoneyVal = this.startMoney.value = startMoneyCur;

    const nVal: number = Math.round(parseInt(priceCur, 10) * (parseFloat(startPercentCur) / 100));
    startMoneyCur = String(nVal);

    this.startMoneyVal = this.startMoney.value = startMoneyCur;
    this.startPercentVal = this.startPercent.value = startPercentCur;
    this.priceVal = this.price.value = priceCur;
    this.interestVal = this.interest.value = interestCur;
    this.yearsVal = this.years.value = yearsCur;

    const pr: any = (this.price.parentElement.parentNode.querySelector('.calc__range') as any);
    pr.noUiSlider.setHandle(0, priceCur, false);

    const it: any = (this.interest.parentElement.parentNode.querySelector('.calc__range') as any);
    it.noUiSlider.setHandle(0, interestCur, false);

    const yr: any = (this.years.parentElement.parentNode.querySelector('.calc__range') as any);
    yr.noUiSlider.setHandle(0, yearsCur, false);
  }

  private resultCalc() {
    const interest: number = parseFloat(this.interestVal) / 100 / 12;
    const months: number = parseInt(this.yearsVal, 10) * 12;

    const percent: number = parseFloat(this.startPercentVal) / 100;
    const price: number = parseInt(this.priceVal, 10);
    const amount: number = Math.round(price - (percent * price));

    const PMT: number = Math.round(this.excelPMT(interest, months, -amount));
    this.elem.querySelector('#loanpay').innerHTML = String(PMT);
    this.elem.querySelector('#loansize').innerHTML = String(amount);
  }

  private setupRangers(wraps: NodeListOf<HTMLElement>) {
    wraps.forEach((wrap: HTMLElement) => {
      const ranger: HTMLElement = wrap.querySelector('.calc__range');

      const options: any = {
        min: wrap.getAttribute('data-min') ? parseInt(wrap.getAttribute('data-min'), 10) : 1,
        max: wrap.getAttribute('data-max') ? parseInt(wrap.getAttribute('data-max'), 10) : 1,
        step: wrap.getAttribute('data-step') ? parseInt(wrap.getAttribute('data-step'), 10) : 1,
        current: wrap.getAttribute('data-current') ? ` ${wrap.getAttribute('data-current')}` : '',
        decimal: wrap.getAttribute('data-decimal') === 'true' ? true : false,
      };

      noUiSlider.create(ranger, {
        start: options.current,
        margin: options.step,
        step: options.step,
        connect: [true, false],
        tooltips: false,
        range: {
          min: options.min,
          max: options.max,
        },
        format: {
          to(val: number) {
            return options.decimal ? Math.round(val * 100) / 100 : Math.round(val);
          },
          from(val: string) {
            return val;
          },
        },
      });

      const tmp: any = (ranger as any).noUiSlider;
      const input: HTMLInputElement = wrap.parentNode.querySelector('input');

      if (tmp && input) {
        tmp.on('set', (values: number[]) => {
          input.value = String(values[0]);
          triggerChange(input);
        });
      }
    });
  }

  private excelPMT (rate: number, nper: number, pv: number, fvv?: number, typee?: number): number {
    const fv: number = (fvv) ? fvv : 0;
    const type: number = (typee) ? typee : 0;

    if (rate === 0) {
      return -(pv + fv) / nper;
    }

    const pvif: number = Math.pow(1 + rate, nper);
    let pmt: number = rate / (pvif - 1) * -(pv * pvif + fv);

    if (type === 1) {
      pmt /= (1 + rate);
    }

    return pmt;
  }
}

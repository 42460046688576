import easing from './easing';

export class MouseMover {
  block: HTMLElement;
  listener: HTMLElement;
  blockWidth: number = 0;

  constructor(selector: string, listener: string) {
    this.block = document.querySelector(selector);
    this.listener = document.querySelector(listener);

    if (this.listener && this.block && this.block.children.length > 3) {
      this.resize();
      window.onresize = () => this.resize();

      this.listener.addEventListener('mousemove', (e: MouseEvent) => {
        const ref: number = this.blockWidth - window.innerWidth;
        const kof: number = e.clientX / window.innerWidth;
        const value: number = ref * easing.easeInOutQuad(kof);
        this.setTransform(value);
      });
    }
  }

  private resize(): void {
    const childWidth: number = (this.block.children[0] as HTMLElement).offsetWidth;
    this.blockWidth = this.block.children.length * childWidth;
  }

  private setTransform(value: number): void {
    if (value !== 0) {
      this.block.style.webkitTransform = `translate3d(-${value}px, 0, 0)`;
      this.block.style.transform = `translate3d(-${value}px, 0, 0)`;
    } else {
      this.block.style.webkitTransform = 'translate3d(0, 0, 0)';
      this.block.style.transform = 'translate3d(0, 0, 0)';
    }
  }
}
